import Alpine from 'alpinejs';
import htmx from 'htmx.org';
import CourseWizard from './custom/course-wizard';
import focus from '@alpinejs/focus';
window.Alpine = Alpine;
window.htmx = htmx;
window.CourseWizard = CourseWizard;

if (window.wpRestNonce !== undefined) {
	document.addEventListener("htmx:configRequest", function (event) {
		event.detail.headers["X-WP-Nonce"] = window.wpRestNonce;
	});
}

Alpine.plugin(focus);

document.addEventListener('alpine:init', () => {
    const courseWizardMenuItems = document.querySelectorAll(
        'a[title="Help Me Find a Course"]'
    );

    courseWizardMenuItems.forEach((item) => {
        item.removeAttribute('href');
        item.removeAttribute('target');
        item.style.cursor = 'pointer';
        item.addEventListener('click', () => {
            Alpine.store('courseWizard').toggle();
        });
    });
    Alpine.store('courseWizard', {
        open: false,
        toggle() {
            this.open = !this.open;
        },
    });
    Alpine.store('nav', {
        open: false,
        toggle() {
            this.open = !this.open;
        },
    });

    Alpine.effect(() => {
        if (Alpine.store('nav').open) {
            document.body.classList.add('overflow-y-hidden');
        } else {
            document.body.classList.remove('overflow-y-hidden');
        }
    });
});

document.addEventListener('DOMContentLoaded', function() {
    Alpine.start();
});
